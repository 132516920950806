import { KurzEnvironmentType } from './kurz-env.type';

export const environment: KurzEnvironmentType = {
  production: true,
  hideRecosys: false,
  baseUrl: undefined, // needs to be undefined and in the cloud the content of the meta tag with name="occ-backend-base-url" is used
  allowedBaseSites: [
    'kurz-de', 'kurz-fr', 'kurz-uk', 'kurz-us', 'kurz-ch', 'kurz-my', 'kurz-nl', 'kurz-au', 'kurz-nz'
  ],
  currentBaseSite: null,
  spartacusVersion: '2211.31',
  smartEdit: {
    storefrontPreviewRoute: 'cx-preview',
    // allowOrigin: 'localhost:9002, api.c0hq918fv6-leonhardk1-d1-public.model-t.cc.commerce.ondemand.com:443, api.c0hq918fv6-leonhardk1-s1-public.model-t.cc.commerce.ondemand.com:443, api.c0hq918fv6-leonhardk1-p1-public.model-t.cc.commerce.ondemand.com:443',
    allowOrigin: 'localhost:9002, *.cc.commerce.ondemand.com:443, backoffice-dev.kurz.shop:443, backoffice-qa.kurz.shop:443, backoffice.kurz.shop:443',
  },
  setAllKurzFeatureFlagsTrue: false,
};

